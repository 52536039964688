<template>
  <el-row :gutter="2">
    <el-col><img src="@/assets/images/Empty Folder.png" alt=""></el-col>
    <el-col>
      <h1>404 Not Found</h1>
      <p>The page you were looking for was not found</p>
    </el-col>
  </el-row>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
  .el-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: calc(100vh - (93px + 60px + 40px));
    width: 80%;
    margin: 0 auto !important;
    text-align: center;

    .el-col {
      width: 100%;
      color: rgb(87, 87, 87);
      img {
        width: 100%;
        min-width: 300px;
        max-width: 500px;
      }
      h1 {
        font-size: 2rem;
        margin: 0;
      }
    }
  }
</style>
